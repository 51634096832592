import React, { useRef } from "react"
import cn from "classnames"
import s from "./InputField.module.scss"
import CountryCodeSelect from "../send-sms/CountryCodeSelect"
// import { getTranslation } from "src/lib/translations"

interface InputFieldProps {
    type: "text" | "number" | "tel" | "email" | "select"
    name: string
    label?: string
    placeholder?: string
    value?: string
    phoneValue?: string[]
    className?: string
    pill?: boolean
    onChange?: (value: string) => void
    // useState hook for phoneValue
    setPhone?: React.Dispatch<React.SetStateAction<string[]>>
    children?: React.ReactNode
    required?: boolean
}


export default function InputField({ type, label, placeholder, name, value, phoneValue, children, onChange, setPhone, className, required = false, pill = false }: InputFieldProps): JSX.Element {
    const phoneInputRef = useRef<HTMLInputElement>()

    return (
        <div className={cn(s["input-field"], className)}>
            {label && (
                <label className={s["input-field__label"]} htmlFor={name}>{label}</label>
            )}

            <div className={cn(s["input-field__container"])}>
                {name === "phone" && (
                    <div className={s["input-field__phone-prefix"]}>
                        <CountryCodeSelect phoneInputRef={phoneInputRef} countryCode={phoneValue?.[0]} setCountryCode={(newCountryCode => {setPhone((oldPhoneValue) => ([newCountryCode, oldPhoneValue[1]]))})} required={required} />
                    </div>
                )}
                {type === "select" && (
                    <select className={cn(s["input-field__input"])} value={value} onChange={(e) => onChange(e.target.value)} id={name} name={name} required={required}>
                        {children}
                    </select>
                )}
                {type !== "select" && (
                    <>
                        <input
                            className={cn(s["input-field__input"], name === "phone" ? s["input-field__input--phone"] : "", pill ? s["input-field__input--pill"] : null )}
                            type={type}
                            name={name}
                            id={name}
                            placeholder={placeholder}
                            value={name == "phone" ? phoneValue[1] : value}
                            {...(type === "number" ? { pattern: "[0-9]*" } : {})}
                            {...(name === "phone" ? { ref: phoneInputRef } : {})}
                            onChange={(e) => typeof setPhone === "function" ? setPhone(sanitizePhoneValue([phoneValue?.[0], e.target.value])) : (typeof onChange === "function" ? onChange(e.target.value) : null)}
                            required={required}
                        />
                        {children ? children : null}
                    </>
                )}
            </div>
        </div>
    )
}


function sanitizePhoneValue([countryCodeInput, phoneInput]: [string, string]) {
    if (!phoneInput) return [countryCodeInput, ""]

    const threeDigitCountryCodes = ["+29", "+35", "+37", "+38", "+42"] // Country codes that start with one of these are actual 3 digit (example: Ireland is +353)
    const phoneStartsWithThreeDigitCountryCode = threeDigitCountryCodes.find(cc => phoneInput.startsWith(cc))
    const countryCodeLength = phoneStartsWithThreeDigitCountryCode ? 4 : 3
    const phoneInputHasCountryCode = phoneInput.startsWith("+") && phoneInput.length >= countryCodeLength
    const parsedCountryCode = phoneInputHasCountryCode ? phoneInput.slice(0, countryCodeLength) : countryCodeInput
    const cleanPhone = (phoneInputHasCountryCode ? phoneInput.slice(countryCodeLength) : phoneInput).replace(/[^+\d]/g, "")

    return [parsedCountryCode, cleanPhone]
}
